import React from "react";
import { assign, assignWith, some, isNil, get } from "lodash";
import { Link } from "react-router-dom";
import { AntdTablePagination } from "components/Table/Table.d";
import { ApolloError } from "apollo-client";
import { Button } from "antd";
import { CollapseIcon, ExpandIcon } from "components/Icons";
import {
  ACFanSpeed,
  ACMode,
  ACStatus,
  AutomationMode,
  DoorStatus,
  KeysFilterInput,
  OccupancyState,
  OperationalMode,
  PositionConfigurationRecordType,
  PositionWithRooms,
} from "./types";
import {
  NodeSubType,
  NodeType,
  Sensorflow_Node_Measurement_View_Bool_Exp,
  PositionConfigurations,
  Sensorflow_Positions_Bool_Exp,
  KeySortInput,
  Maybe,
} from "../../pacts/app-webcore/hasura-webcore.graphql";
import { formatTime24h } from "../../utils/date";
import { Key } from "./KeyList/KeyList.d";

export const MAX_TEMP = 32;
export const MIN_TEMP = 16;
export const NSI_MIN_TEMP_RELATIVE_INCREASE = 1;
export const NSI_MAX_TEMP_RELATIVE_INCREASE = 3;
export const NSI_MAX_TEMP = 23;

export const nodeTypes = [
  {
    key: NodeType.Occupancy.toString(),
    value: "Occupancy",
  },
  {
    key: NodeType.Door.toString(),
    value: "Door",
  },
  {
    key: NodeType.Aircon.toString(),
    value: "Aircon",
  },
];

export const NotUpdated = () => {
  return <span className="text-warning font-weight-bold">Not Updated</span>;
};

export const NotAvailable = () => {
  return <span className="text-gray font-weight-bold">Not Available</span>;
};

export const isAutomate24h = (acNightStart: string | null | undefined, acNightEnd: string | null | undefined) => {
  if (!acNightStart || !acNightEnd) return false;
  return acNightStart === "23:59" && acNightEnd === "00:00";
};

export const nodeSubTypes = [
  {
    nodeType: NodeType.Occupancy.toString(),
    key: NodeSubType.OccupancyCeiling.toString(),
    value: "Ceiling Occupancy",
  },
  {
    nodeType: NodeType.Occupancy.toString(),
    key: NodeSubType.OccupancyWall.toString(),
    value: "Wall Occupancy",
  },
  {
    nodeType: NodeType.Door.toString(),
    key: NodeSubType.DoorLaser.toString(),
    value: "Door Laser",
  },
  {
    nodeType: NodeType.Door.toString(),
    key: NodeSubType.DoorMagnetic.toString(),
    value: "Door Magnetic",
  },
  {
    nodeType: NodeType.Aircon.toString(),
    key: NodeSubType.AirconAcir.toString(),
    value: "ACIR",
  },
  {
    nodeType: NodeType.Aircon.toString(),
    key: NodeSubType.AirconDaikin.toString(),
    value: "Daikin",
  },
  {
    nodeType: NodeType.Aircon.toString(),
    key: NodeSubType.Aircon_2pfc.toString(),
    value: "2PFC",
  },
  {
    nodeType: NodeType.Aircon.toString(),
    key: NodeSubType.Aircon_4pfc.toString(),
    value: "4PFC",
  },
];

export const getAutomationModeText = (acMode: string) => {
  switch (acMode) {
    case AutomationMode.SuperSave:
      return "SuperSave";
    case AutomationMode.SmartSave:
      return "SmartSave";
    case AutomationMode.Disabled:
      return "Disabled";
    default:
      return "";
  }
};

export const getOperationalText = (operationalMode: string) => {
  switch (operationalMode) {
    case OperationalMode.Cooling:
      return "Cooling";
    case OperationalMode.Heating:
      return "Heating";
    default:
      return "";
  }
};

export const getOccupancyText = (occupancy: OccupancyState) => {
  switch (occupancy) {
    case OccupancyState.Occupied:
      return "Occupied";
    case OccupancyState.Unoccupied:
      return "Unoccupied";
    default:
      return "Not Updated";
  }
};

export const getACText = (acStatus: ACStatus | Maybe<string> | undefined) => {
  switch (acStatus) {
    case ACStatus.On:
      return "On";
    case ACStatus.Off:
      return "Off";
    default:
      return <NotUpdated />;
  }
};

export const getACModeText = (acMode: ACMode | Maybe<string> | undefined) => {
  switch (acMode) {
    case ACMode.Auto:
      return "Auto";
    case ACMode.Cool:
      return "Cool";
    case ACMode.Dry:
      return "Dry";
    case ACMode.Fan:
      return "Fan";
    case ACMode.Heat:
      return "Heat";
    default:
      return "Not Updated";
  }
};

export const getACFanSpeedText = (fanSpeed: ACFanSpeed | Maybe<string> | undefined, shortForm = false) => {
  switch (fanSpeed) {
    case ACFanSpeed.Auto:
      return "Auto";
    case ACFanSpeed.Low:
      return shortForm ? "Lo" : "Low";
    case ACFanSpeed.Medium:
      return shortForm ? "Med" : "Medium";
    case ACFanSpeed.High:
      return shortForm ? "Hi" : "High";
    default:
      return "Not Updated";
  }
};

export const getDoorStatusText = (value: any) => {
  if (value === DoorStatus.Close) return "Closed";
  if (value === DoorStatus.Open) return "Open";
  return "Not updated";
};

export const getOccupancyStateText = (value: any) => {
  if (value === OccupancyState.Unoccupied) return "Vacant";
  if (value === OccupancyState.Occupied) return "Occupied";
  return "Not updated";
};

export const getUnitDisplayText = (val: any, suffix: string, emptyString: string = "NA") => {
  if (!isNil(val)) {
    return `${val}${suffix}`;
  }
  return emptyString;
};

export const formatTemp = (value: any, emptyValue?: string) => {
  return getUnitDisplayText(value, "°C", emptyValue);
};

export const parseTemp = (value?: string) => {
  return value ? parseInt(value.replace("°C", "")) : 0;
};

const customizer = (objectVal: any, sourceVal: any): any => {
  if (Array.isArray(objectVal) && Array.isArray(sourceVal)) return objectVal.concat(sourceVal);
  if (typeof objectVal === "object" && typeof sourceVal === "object")
    return assignWith(objectVal, sourceVal, customizer);
  return objectVal === undefined ? sourceVal : objectVal;
};

function filterByKeyName(
  filter: KeysFilterInput,
  filterConditions: Sensorflow_Positions_Bool_Exp,
  isSearchByKeyName: boolean = true,
  isSearchByRoomName: boolean = false
) {
  if (filter.positionName && filter.positionName !== "") {
    assign(filterConditions, {
      _or: [
        {
          positionName: {
            _ilike: isSearchByKeyName ? `%${filter.positionName}%` : ``,
          },
        },
        {
          rooms: {
            positionName: {
              _ilike: isSearchByRoomName ? `%${filter.positionName}%` : ``,
            },
          },
        },
      ],
    });
  }
}

function filterByCategoryNames(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.categories && filter.categories.length > 0) {
    assign(filterConditions, {
      keyCategoryPositionMapping: {
        keyCategory: {
          categoryName: {
            _in: filter.categories,
          },
        },
      },
    });
  }
}

function filterByDoorStatus(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.doors && filter.doors.length > 0) {
    const emptyCondition: Sensorflow_Node_Measurement_View_Bool_Exp[] = [];
    if (some(filter.doors, (door) => door === DoorStatus.NotUpdated.toString())) {
      emptyCondition.push({
        door: { _is_null: true },
      });
    }

    assignWith<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(
      filterConditions,
      {
        rooms: {
          nodeMeasurements: {
            _and: [{ _or: [{ door: { _in: filter.doors } }, ...emptyCondition] }],
          },
        },
      },
      customizer
    );
  }
}

function filterByACStatus(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.acStatuses && filter.acStatuses.length > 0) {
    const emptyCondition: Sensorflow_Node_Measurement_View_Bool_Exp[] = [];
    if (some(filter.acStatuses, (status) => status === ACStatus.NotUpdated.toString())) {
      emptyCondition.push({
        acStatus: { _is_null: true },
      });
    }

    assignWith<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(
      filterConditions,
      {
        rooms: {
          nodeMeasurements: {
            _and: [{ _or: [{ acStatus: { _in: filter.acStatuses } }, ...emptyCondition] }],
          },
        },
      },
      customizer
    );
  }
}

function filterByAutomationModes(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.automationModes && filter.automationModes.length > 0) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          automationMode: {
            _in: filter.automationModes,
          },
        },
      },
    });
  }
}

function filterByIsDeviatedFromDefaultConfig(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.isDeviatedFromDefaultConfig && filter.isDeviatedFromDefaultConfig.length > 0) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          isDeviatedFromDefaultConfig: {
            _eq: !!filter.isDeviatedFromDefaultConfig.find((val) => val === "1"),
          },
        },
      },
    });
  }
}

function filterBySetpointLimits(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.setpointLimitMin) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          minTemp: {
            _gte: parseTemp(filter.setpointLimitMin),
          },
        },
      },
    });
  }

  if (filter.setpointLimitMax) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          maxTemp: {
            _lte: parseTemp(filter.setpointLimitMax),
          },
        },
      },
    });
  }
}

function filterByAutomationHours(filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) {
  if (filter.automationHourStart) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          acNightStart: {
            _eq: formatTime24h(filter.automationHourStart),
          },
        },
      },
    });
  }

  if (filter.automationHourStop) {
    assign<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(filterConditions, {
      rooms: {
        positionConfiguration: {
          recordType: { _eq: "CURRENT" },
          acNightEnd: {
            _eq: formatTime24h(filter.automationHourStop),
          },
        },
      },
    });
  }
}

const filterByOccupancyStates = (filter: KeysFilterInput, filterConditions: Sensorflow_Positions_Bool_Exp) => {
  if (filter.occupancies && filter.occupancies.length > 0) {
    const emptyCondition: Sensorflow_Node_Measurement_View_Bool_Exp[] = [];
    if (some(filter.occupancies, (occ) => occ === OccupancyState.NotUpdated.toString())) {
      emptyCondition.push({
        occupancyState: { _is_null: true },
      });
    }

    assignWith<Sensorflow_Positions_Bool_Exp, Sensorflow_Positions_Bool_Exp>(
      filterConditions,
      {
        rooms: {
          nodeMeasurements: {
            _and: [{ _or: [{ occupancyState: { _in: filter.occupancies } }, ...emptyCondition] }],
          },
        },
      },
      customizer
    );
  }
};

export const getPositionQueryFilter = (
  locationId: string,
  filter: KeysFilterInput,
  isSearchByKeyName: boolean = true,
  isSearchByRoomName: boolean = false
) => {
  const filterConditions: Sensorflow_Positions_Bool_Exp = {
    locationId: {
      _eq: locationId,
    },
    positionType: { _eq: "key" },
  };

  filterByKeyName(filter, filterConditions, isSearchByKeyName, isSearchByRoomName);
  filterByCategoryNames(filter, filterConditions);
  filterByOccupancyStates(filter, filterConditions);
  filterByDoorStatus(filter, filterConditions);
  filterByACStatus(filter, filterConditions);
  filterByAutomationModes(filter, filterConditions);
  filterByIsDeviatedFromDefaultConfig(filter, filterConditions);
  filterBySetpointLimits(filter, filterConditions);
  filterByAutomationHours(filter, filterConditions);

  return filterConditions;
};

export const getCurrentOrDefaultPositionConfig = (
  positionConfigs?: PositionConfigurations[]
): PositionConfigurations | null => {
  if (!positionConfigs || positionConfigs.length === 0) return null;
  const current = positionConfigs.find((pc) => pc.recordType === PositionConfigurationRecordType.CURRENT);
  if (current) return current;
  return positionConfigs.find((pc) => pc.recordType === PositionConfigurationRecordType.DEFAULT) ?? null;
};

export type KeyListPagination = {
  pageSizeOption: string[];
  showSizeChanger: boolean;
  current: number;
  pageSize: number;
  total: number;
};

export type KeyListTableProps = {
  tableData: Key[];
  sortBy: (columnHeader: keyof KeySortInput) => void;
  sort: KeySortInput;
  pagination: KeyListPagination;
  handleTableChange: (desiredPagination: AntdTablePagination) => void;
  loading: boolean;
  error?: ApolloError;
  rowSelection?: any;
};

export const getDefaultColumnKeyListTable = (props: KeyListTableProps, locationId: string) => {
  const { sort } = props;
  const sortOrder = (value: any) => get({ ASC: "ascend", DESC: "descend" }, value, "");
  return [
    {
      title: "Key",
      onHeaderCell: () => ({
        onClick: () => {
          props.sortBy("keyName");
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort.keyName),
      isVisible: true,
      render: (key: Key) => {
        return <Link to={`/locations/${locationId}/keys/${key.keyId}`}>{key.keyName}</Link>;
      },
      isShowOnMobile: false,
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      onHeaderCell: () => ({
        onClick: () => {
          props.sortBy("categoryName");
        },
      }),
      sorter: true,
      defaultSortOrder: sortOrder(sort.categoryName),
      isVisible: true,
      isShowOnMobile: false,
    },
  ];
};

export const roomDashboardLink = (locationId: string, locationName: string, keyName: string, roomName: string) => {
  const location = locationName.replace(/\s+/g, "%20");
  const key = keyName.replace(/\s+/g, "%20");
  const room = roomName.replace(/\s+/g, "%20");
  return `https://monitoring.sensorflow.cloud:3000/d/sVvRdRdZk/room-view?orgId=1&var-location=${location}&var-location_id=${locationId}&var-key=${key}&var-room=${room}`;
};

export const expandAndCollapseButton = (
  keys: PositionWithRooms[],
  onExpandAll: (expanded: boolean, keys: PositionWithRooms[]) => void
) => {
  return (
    <div>
      <Button
        type="primary"
        icon={<ExpandIcon className="mr-xs" />}
        className="mr-s"
        onClick={() => onExpandAll(true, keys)}
      >
        Expand all keys
      </Button>
      <Button type="primary" icon={<CollapseIcon className="mr-xs" />} onClick={() => onExpandAll(false, keys)}>
        Collapse all keys
      </Button>
    </div>
  );
};

export type MappedNode = {
  nodeMacId?: string;
  type: string;
  subType?: string | null;
};

export const isShowBaseACModelDropdown = (
  slotNodeType: string,
  slotNodeSubType?: string | null,
  mappedNode?: MappedNode | null
) => {
  if (mappedNode) {
    if (mappedNode.subType === NodeSubType.AirconAcir && mappedNode.type === NodeType.Aircon) return true;
  }
  if (slotNodeSubType === NodeSubType.AirconAcir && slotNodeType === NodeType.Aircon) return true;
  return false;
};
