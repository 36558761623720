/* eslint-disable */
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { SchemaLink } from "apollo-link-schema";
import faker from "faker";
import { GraphQLError } from "graphql";
import { PubSub, withFilter } from "graphql-subscriptions";
import { addMocksToSchema, makeExecutableSchema } from "graphql-tools";
import { cloneDeep, drop, find, findIndex, get, isEmpty, isEqual, orderBy, pick, random, sortBy, take } from "lodash";
import moment from "moment-timezone";
import Moment from "moment";
import {
  Gateway,
  GatewayHardwareType,
  GatewayHealthStatus,
  MappingStatus,
  Sensorflow_Node_To_Slot_Mapping_Bool_Exp,
  Sensorflow_Node_To_Slot_Mapping_Order_By,
  Sensorflow_Warehouse_Kitting_Process_Metadata,
} from "pacts/app-webcore/hasura-webcore.graphql";
import pactSchema from "pacts/app-webcore/schema";
import { InfrastructureDetailType } from "pages/InfrastructureDetails/InfrastructureDetails.d";
import { filterKey, filterLocation, filterNodes, sortKey, sortLocation, sortNodes } from "testUtils";
import { formatUTCTimestamp } from "utils/date";
import { v4 as uuid } from "uuid";
import { NodeMappingError } from "../../components/NodeMapping/NodeMappingError.d";
import dummyUsers from "./dummyUsers.json";
import dummyLocations from "./locations.json";
import ACModelsData from "./mock-data/ACModels.json";
import customerKeyDetailJson from "./mock-data/customerKeyDetailMockData";
import dummyGateways from "./mock-data/dummyGateways";
import dummyLocationMetadata from "./mock-data/dummyLocationMetadata.json";
import dummyNodeJoinStatuses from "./mock-data/dummyNodeJoinStatuses";
import dummyNodeOnlineStatuses from "./mock-data/dummyNodeOnlineStatuses";
import emptyLocation from "./mock-data/emptyLocation.json";
import eventStreamMockData from "./mock-data/eventStreamMockData";
import firmwareReleases from "./mock-data/firmwareReleases.json";
import gateways from "./mock-data/gateways.json";
import infrastructureData from "./mock-data/infrastructure.json";
import infrastructuresData from "./mock-data/infrastructures.json";
import keyCategories from "./mock-data/keyCategories";
import keyCategoriesCreation from "./mock-data/keyCategoriesCreation.json";
import keyEntries from "./mock-data/keyEntries";
import keyListForCustomer from "./mock-data/keyListForCustomer";
import keysInLocations from "./mock-data/keysInLocations";
import locationKeysAndRooms from "./mock-data/locationKeysAndRooms.json";
import keysLabelDetailsData from "./mock-data/mock-keys.json";
import nodeMappedPreviously from "./mock-data/nodeMappedPreviously.json";
import nodes from "./mock-data/nodes.json";
import positionSubscription from "./mock-data/positionSubscription";
import rapidMappings from "./mock-data/rapidMapping.json";
import roomsMetaData from "./mock-data/roomsMetaData";
import roomsWithEnergyConsumption from "./mock-data/roomsWithEnergyConsumption";
import rootCauses from "./mock-data/rootCauses.json";
import smartAllocReport from "./mock-data/smartAllocReport";
import smartRemValidationHistory from "./mock-data/smartRemValidationHistory";
import userWithAuthTokens from "./mock-data/userWithAuthTokens.json";
import dummyOrganisations from "./organisations.json";
import dummyRoles from "./roles.json";
import positionConfigurations from "./mock-data/positionConfigurations.json";
import { PrecoolSchedule } from "./mock-comfortplus-data";
import activityLogs from "./mock-data/ActivityLogs.json";

const pubsub = new PubSub();

const GATEWAY_KITTING_PROCESS_METADATA_SUBSCRIPTION = "GATEWAY_KITTING_PROCESS_METADATA_SUBSCRIPTION";

const filterUsers = (filters: any) => {
  // if no filters, just return the whole list
  if (!filters) {
    return dummyUsers;
  }
  const { name, email, roleIds } = filters;
  let filteredUsers = dummyUsers;

  // TODO: write this more elegantly
  if (name) {
    filteredUsers = filteredUsers.filter((user) => {
      return user.name.toUpperCase().includes(name.toUpperCase());
    });
  }
  if (email) {
    filteredUsers = filteredUsers.filter((user) => {
      return user.email.toUpperCase().includes(email.toUpperCase());
    });
  }
  if (roleIds) {
    filteredUsers = filteredUsers.filter((user) => {
      return user.role && roleIds.indexOf(user.role?.id) !== -1;
    });
  }

  //  TODO 2020-08-31: mock role field when working on roles branch */}
  // if (role) {
  //   filteredUsers = filteredUsers.filter((user) => {
  //     return user.roles.includes(role);
  //   });
  // }

  return filteredUsers;
};

const sortByZone = (name1: string, name2: string) => {
  const firstOffset = moment.tz(name1).utcOffset();
  const secondOffset = moment.tz(name2).utcOffset();
  return firstOffset - secondOffset;
};

const buildNewCurrentKey = (currentRapidMappingKey: any, nextRapidMappingKey: any) => {
  if (!nextRapidMappingKey) return null;

  const newCurrentKey = cloneDeep(currentRapidMappingKey);
  newCurrentKey.keyId = nextRapidMappingKey.keyId;
  newCurrentKey.keyName = nextRapidMappingKey.keyName;
  newCurrentKey.currentRapidMappingRoom.roomId = nextRapidMappingKey.nextRapidMappingRoom.roomId;
  newCurrentKey.currentRapidMappingRoom.roomName = nextRapidMappingKey.nextRapidMappingRoom.roomName;
  newCurrentKey.currentRapidMappingRoom.slots = nextRapidMappingKey.nextRapidMappingRoom.slots.map(
    (slot: any, index: number) => {
      return { ...slot, isCurrentRapidMappingSlot: index === 0, mappingStatus: "NOT_STARTED" };
    }
  );

  return newCurrentKey;
};

class LocationCommand {
  locationName: string;

  constructor(locationName: string) {
    this.locationName = locationName;
  }
}

class LocationCreate extends LocationCommand {
  locationId: string;
  currency: string;
  organisationName: string;
  timezone: string;

  constructor(locationName: string, currency: string, organisationName: string, timezone: string) {
    super(locationName);
    this.locationId = faker.random.uuid();
    this.currency = currency;
    this.organisationName = organisationName;
    this.timezone = timezone;
  }
}

class CreateUpdateLocationTracker {
  addUpdate(locationId: string, locationName: string) {
    let mockupLocations = keysInLocations;
    const location = mockupLocations.find((location) => location.id === locationId);
    if (!location) {
      return;
    }
    location.locationName = locationName;
  }

  addCreate(locationName: string, currency: string, organisationName: string, timezone: string) {
    const newLocation = new LocationCreate(locationName, currency, organisationName, timezone);
    let mockupLocations = [...keysInLocations];
    mockupLocations.push({
      ...emptyLocation,
      id: newLocation.locationId,
      locationName: newLocation.locationName,
      locationMetadata: {
        showEnergyConsumption: true,
        subscription: "SMART_REM",
      },
    });
    return newLocation;
  }
}

const LocationCommandTrackerSingleton = (() => {
  let instance: CreateUpdateLocationTracker | undefined;

  const createInstance = () => {
    return new CreateUpdateLocationTracker();
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    },
  };
})();

class CreateUpdateKeyCategoryTracker {
  addCreate(locationName: string, categoryName: string) {
    keyCategories.push({
      id: faker.random.uuid(),
      locationName,
      categoryName,
    });
  }

  addUpdate(id: string, categoryName: string) {
    let keyCategory = keyCategories.find((category) => category.id === id);
    if (keyCategory) {
      keyCategory.categoryName = categoryName;
    }
  }
}

const KeyCategoryCommandTrackerSingleton = (() => {
  let instance: CreateUpdateKeyCategoryTracker | undefined;

  const createInstance = () => {
    return new CreateUpdateKeyCategoryTracker();
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    },
  };
})();

const keyCategoryCommandTracker = KeyCategoryCommandTrackerSingleton.getInstance();

let subscribePayloadCounts: any = {};

// work around to prevent too much rerender on mock data subscription
setInterval(() => {
  subscribePayloadCounts = {};
}, 2000);

export const resetSubscriptionCount = () => {
  subscribePayloadCounts = {};
};

export const getLocation = (locationId: string) => {
  let mockupLocations = keysInLocations;
  return mockupLocations.find((location) => location.id === locationId);
};

let isActivateComfortPlus = false;
let comfortPlusData = {
  id: 60,
  checkInAt: "2023-11-18T07:00:00+00:00",
  checkOutAt: "2023-11-19T05:00:00+00:00",
  keyPositionId: "c5586e9a-c356-4008-82fe-211a53798702",
  status: null,
  comfortplusActivations: [
    {
      precoolDailyLimit: 3,
      __typename: "sensorflow_comfortplus_activations",
    },
  ],
  reservationGuests: [
    {
      user: {
        userId: "d7f18454-a0c6-4842-836d-362cbef07dcc",
        name: "email",
        email: "",
        __typename: "sensorflow_users",
      },
      comfortplusAccessToken: "mF3nYI1tycYwrl0JBHSEhJWst3i0z8DQqAQQUJBbAQa-6oW5zw",
      __typename: "sensorflow_reservation_guests",
    },
  ],
  __typename: "sensorflow_reservations",
};

const reinitMockResolvers = () => {
  const locationCommandTracker = LocationCommandTrackerSingleton.getInstance();
  let mockupLocations = [...keysInLocations];
  let keysLabelDetails = [...keysLabelDetailsData];
  let firmwareReleasesData = [...firmwareReleases];
  let positionsSubscriptionConditions: any;
  let positionSubscriptionData = [...positionSubscription];
  let mockPositionConfigurations = [...positionConfigurations];

  let customerKeyDetailMockData = cloneDeep(customerKeyDetailJson);
  let archiveCustomerKeyDetailMockData: typeof customerKeyDetailMockData;

  return {
    mutation_root: {
      userCreate: (root: any, args: any) => {
        const { name, roleId, organisationId, locationIds } = args.user;
        return {
          userId: faker.random.uuid(),
          email: args.email,
          name,
          role: dummyRoles.find((role) => role.id === roleId),
          organisation: dummyOrganisations.find((organisation) => organisation.id === organisationId),
          locations: dummyLocations.filter((location) => locationIds.includes(location.id)),
          status: "ACTIVE",
          emailVerified: "UNVERIFIED",
          lastLogin: "x",
          updatedAt: "x",
        };
      },
      userUpdate: (root: any, args: any) => {
        const { name, roleId, organisationId, locationIds } = args.user;
        return {
          userId: () => args.userId,
          email: () => dummyUsers[0].email,
          name,
          role: dummyRoles.find((role) => role.id === roleId),
          organisation: dummyOrganisations.find((organisation) => organisation.id === organisationId),
          locations: dummyLocations.filter((location) => locationIds.includes(location.id)),
          status: "ACTIVE",
          emailVerified: "UNVERIFIED",
        };
      },
      mapNode: (root: any, args: any) => {
        const { nodeMacInput, roomId, slotName, nodeType } = args;
        // success case
        if (nodeMacInput === "04FFFF14B4570DF900" || nodeMacInput === "03FFFF000B575C3663") {
          const location = rapidMappings.find(
            (rapidMapping) => rapidMapping.rapidMapping.currentRapidMappingKey.currentRapidMappingRoom.roomId === roomId
          );
          const rapidMappingResponse = cloneDeep(location!.rapidMapping);
          const slots = get(rapidMappingResponse, "currentRapidMappingKey.currentRapidMappingRoom.slots", []);
          const currentSlotIndex = findIndex(slots, ({ isCurrentRapidMappingSlot }) => isCurrentRapidMappingSlot);
          slots[currentSlotIndex].isCurrentRapidMappingSlot = false;
          slots[currentSlotIndex].mappingStatus = "MAPPED";

          if (currentSlotIndex < slots.length - 1) {
            slots[currentSlotIndex + 1].isCurrentRapidMappingSlot = true;
          } else {
            // customize response, currentRapidMappingKey = first node in nextRapidMappingKey
            rapidMappingResponse.keysStats.mappedCount += 1;
            rapidMappingResponse.currentRapidMappingKey = buildNewCurrentKey(
              rapidMappingResponse.currentRapidMappingKey,
              rapidMappingResponse.nextRapidMappingKey
            );
          }

          return rapidMappingResponse;
        }

        // NODE_ALREADY_MAPPED_ERROR
        if (nodeMacInput === "04FFFF14B4570DF911") {
          throw new GraphQLError("Node is already mapped", null, null, null, ["mapNode"], null, {
            mappedNodeInSlot: {
              locationName: "Pigeon Hotel",
              keyName: "Ascott 1104",
              roomName: "Bedroom",
            },
            code: NodeMappingError.NodeAlreadyMappedError,
          });
        }

        // NODE_MAPPING_ERROR
        if (nodeMacInput === "04FFFF14B4570DF922") {
          throw new GraphQLError("Node mapped failed", null, null, null, ["mapNode"], null, {
            code: NodeMappingError.NodeMappingError,
          });
        }

        // Something went wrong
        if (nodeMacInput === "04FFFF14B4570DF923") {
          throw new GraphQLError("Unknown Error");
        }

        const key = find(keysLabelDetails, (k) => {
          return k.rooms.find((x) => x.roomId === roomId);
        }) as (typeof keysLabelDetails)[number];

        if (key) {
          const room = key.rooms.find((x) => x.roomId === roomId);
          const slot = room?.slots.find((s) => s.slotName === slotName && s.nodeType === nodeType);
          if (slot) {
            const nodeId = nodeMacInput.slice(2);
            slot.currentMappedNode.mappedNode.nodeMacId = nodeId;
            return key;
          }
        }

        throw new GraphQLError("Unknown Error");
      },
      skipRapidMapping: (root: any, args: any) => {
        const { roomId } = args;

        const location = rapidMappings.find(
          (rapidMapping) => rapidMapping.rapidMapping.currentRapidMappingKey.currentRapidMappingRoom.roomId === roomId
        );
        const rapidMappingResponse = cloneDeep(location!.rapidMapping);
        const slots = get(rapidMappingResponse, "currentRapidMappingKey.currentRapidMappingRoom.slots", []);
        const currentSlotIndex = findIndex(slots, ({ isCurrentRapidMappingSlot }) => isCurrentRapidMappingSlot);
        slots[currentSlotIndex].isCurrentRapidMappingSlot = false;
        slots[currentSlotIndex].mappingStatus = "SKIPPED";

        if (currentSlotIndex < slots.length - 1) {
          slots[currentSlotIndex + 1].isCurrentRapidMappingSlot = true;
        } else {
          // customize response, currentRapidMappingKey = first node in nextRapidMappingKey
          rapidMappingResponse.currentRapidMappingKey = buildNewCurrentKey(
            rapidMappingResponse.currentRapidMappingKey,
            rapidMappingResponse.nextRapidMappingKey
          );
        }

        return rapidMappingResponse;
      },
      updateLocation: (root: any, args: any) => {
        const {
          pk_columns: { locationId },
          _set: { locationName },
        } = args;
        locationCommandTracker.addUpdate(locationId, locationName);
      },
      createLocation: (root: any, args: any) => {
        const {
          object: {
            locationId,
            locationName,
            timezone,
            currency,
            organisation: {
              data: { name },
            },
          },
        } = args;
        if (locationName === "A new location") {
          return locationCommandTracker.addCreate(locationName, currency, name, timezone);
        }
        if (locationId) {
          return locationCommandTracker.addUpdate(locationId, locationName);
        }

        throw new GraphQLError("Create a new location is failed");
      },
      createKeyCategory: (root: any, args: any) => {
        const {
          object: { categoryName, locationName },
        } = args;

        if (categoryName.includes("fail")) {
          throw new GraphQLError("Create a new category failed");
        }

        return keyCategoryCommandTracker.addCreate(locationName, categoryName);
      },
      updateKeyCategory: (root: any, args: any) => {
        const {
          pk_columns: { id: id },
          _set: { categoryName },
        } = args;

        if (categoryName.includes("fail")) {
          throw new GraphQLError("Update category failed");
        }
        return keyCategoryCommandTracker.addUpdate(id, categoryName);
      },
      createRoom: (root: any, args: any) => {
        const input = args;
        const key = keysLabelDetails.find((p) => p.keyId === input.parentPositionId);
        if (key) {
          const slots = input.slots.map((n: any) => {
            return {
              id: faker.random.uuid(),
              slotName: n.slotName,
              nodeType: n.nodeType,
            };
          });
          let obj = {
            roomId: faker.random.uuid(),
            roomName: input.positionName,
            slots: slots,
          };
          key.rooms.push(obj as any);
        }
      },
      updatePosition: (root: any, args: any) => {
        const {
          pk_columns: { positionId },
          _set: { positionName },
        } = args;
        let key = keysLabelDetails.find((p) => p.keyId === positionId);
        if (key) {
          key.keyName = positionName;
          return {
            positionId,
            positionName,
          };
        }
        for (key of keysLabelDetails) {
          const room = key.rooms.find((p) => p.roomId === positionId);
          if (room) {
            room.roomName = positionName;
            return {
              positionId,
              positionName,
            };
          }
        }
      },
      deletePosition: (root: any, args: any) => {
        const { positionId } = args;
        // Delete key from keysLabelDetails
        let index = keysLabelDetails.findIndex((k) => k.keyId === positionId);
        if (index > -1) {
          keysLabelDetails.splice(index, 1);
          return;
        }
        // Delete room
        for (const key of keysLabelDetails) {
          index = key.rooms.findIndex((r) => r.roomId === positionId);
          if (index > -1) {
            key.rooms.splice(index, 1);
            return {
              positionId: positionId,
            };
          }
        }
        // Delete position (key & room) from mockupLocations
        for (const location of mockupLocations) {
          const keyIndex = location.keysResponse.keys.findIndex((k) => k.keyId === positionId);
          if (keyIndex > -1) {
            location.keysResponse.keys.splice(keyIndex, 1);
            return {
              positionId: positionId,
            };
          }
        }
      },
      createSlot: (root: any, args: any) => {
        const { nodeType, parentPositionId, slotName } = args.object;

        for (const key of keysLabelDetails) {
          const room = key.rooms.find((r) => r.roomId === parentPositionId);
          if (room) {
            let newSlot = {
              id: faker.random.uuid(),
              nodeType: nodeType,
              slotName: slotName,
              nodeTypeCodeIdentifier: "NODE_TYPE_CODE_03",
            };
            room.slots.push(newSlot as any);
            return newSlot;
          }
        }
      },
      updateSlot: (root: any, args: any) => {
        const id = args.pk_columns.id;
        const slotName = args._set.slotName;
        for (const key of keysLabelDetails) {
          for (const room of key.rooms) {
            let slot = room.slots.find((r) => r.id === id);
            if (slot) {
              slot.slotName = slotName;
              return {
                id: id,
                slotName,
              };
            }
          }
        }
      },
      deleteSlot: (root: any, args: any) => {
        const { id } = args;
        // Delete slot
        for (const key of keysLabelDetails) {
          for (const room of key.rooms) {
            let index = room.slots.findIndex((r) => r.id === id);
            if (index > -1) {
              room.slots.splice(index, 1);
              return {
                id: id,
              };
            }
          }
        }
      },
      updateKeyCategoryToKeyMappings: (root: any, args: any) => {
        const key = keysLabelDetails.find((p) => p.keyId === args.where.positionId._eq);
        if (key) {
          key.categoryName = args._set.categoryName;
        }
      },
      updatePositionConfiguration: (root: any, args: any) => {
        const id = args.positionConfiguration.positionId;
        const acModelId = args.positionConfiguration.acModelId;

        const bedroom3 = customerKeyDetailMockData.rooms.find((x) => x.positionId === id);
        if (bedroom3) {
          archiveCustomerKeyDetailMockData = cloneDeep(customerKeyDetailMockData);
          const { positionConfiguration } = args;
          const currentConfig = (bedroom3.positionConfiguration as any).find(
            (x: any) => x.recordType === "CURRENT"
          ) as (typeof bedroom3.positionConfiguration)[number];
          currentConfig.automationMode = positionConfiguration.automationMode;
          currentConfig.expiredAt = Moment(Moment.now()).add(5, "s").format() as any;
          console.log(`expiredAt`, currentConfig.expiredAt);
          return bedroom3.positionConfiguration;
        }

        for (const key of keysLabelDetails) {
          const room = key.rooms.find((r) => r.positionConfiguration && r.positionConfiguration.id === id);
          if (room) {
            room.positionConfiguration.acModelId = acModelId;
          }
        }
        return {
          id,
          acModelId,
        };
      },
      createLocationMetadata: (root: any, args: any) => {
        const {
          object: { wifiSsid },
        } = args;

        if (wifiSsid && wifiSsid.includes("fail")) {
          throw new GraphQLError("Fail creating location metadata");
        }
      },
      updateLocationMetadata: (root: any, args: any) => {
        const {
          _set: { wifiSsid },
        } = args;

        if (wifiSsid && wifiSsid.includes("fail")) {
          throw new GraphQLError("Fail updating location metadata");
        }
      },
      gatewaysCreate: (root: any, args: any) => {
        const { gateways: inputGateways } = args;

        if (inputGateways.find((gateway: any) => gateway.gatewayName && gateway.gatewayName.includes("fail"))) {
          throw new GraphQLError("Fail creating gateways");
        }

        const newGateways = inputGateways.map(
          (gateway: Partial<Gateway>) =>
            ({
              gatewayId: uuid(),
              locationId: gateway.locationId || "",
              gatewayName: gateway.gatewayName,
              gatewayMac: 1234,
              healthStatus: GatewayHealthStatus.PreparingImage,
              error: null,
            } as any)
        );

        gateways.push(...newGateways);
        return newGateways;
      },
      deleteGateway: (root: any, args: any) => {
        const { gatewayId } = args;

        const gatewayIndex = gateways.findIndex((gateway) => gateway.gatewayId === gatewayId);

        if (gatewayIndex > -1) {
          gateways.splice(gatewayIndex, 1);
        }

        pubsub.publish(GATEWAY_KITTING_PROCESS_METADATA_SUBSCRIPTION, gateways);

        return {
          gatewayId,
        };
      },
      renameGateway: (root: any, args: any) => {
        const { gatewayId, gatewayName } = args;

        if (gatewayName.includes("FAIL")) {
          throw new GraphQLError("Error rename gateway.");
        }

        const gatewayIndex = gateways.findIndex((gateway) => gateway.gatewayId === gatewayId);

        if (gatewayIndex > -1) {
          gateways.splice(gatewayIndex, 1, {
            ...gateways[gatewayIndex],
            gatewayName,
          });
        }

        return {
          gatewayId,
          gatewayName,
        };
      },

      updateGatewayHardwareType: (root: any, args: any) => {
        const { gatewayId, type } = args;

        const gatewayIndex = gateways.findIndex((gateway) => gateway.gatewayId === gatewayId);

        if (gatewayIndex > -1) {
          gateways.splice(gatewayIndex, 1, {
            ...gateways[gatewayIndex],
            hardware_type: type,
          });
        }

        return {
          gatewayId,
          gatewayName: gateways[gatewayIndex].gatewayName,
          hardwareType: type,
        };
      },

      createRoomCompressors: async (root: any, args: any) => {
        if (find(args.compressors, { compressorName: "Yellow Flame 04-01 - Yellow Flame 04-01_Room3" }))
          throw new GraphQLError("Something went wrong.");
      },
      deleteFirmwareRelease: (root: any, args: any) => {
        const { id } = args;
        firmwareReleasesData = firmwareReleasesData.filter((fw) => fw.id !== id);
        return {
          id,
        };
      },
      updateFirmwareRelease: (root: any, args: any) => {
        const {
          pk_columns: { id },
          _set,
        } = args;
        firmwareReleasesData = firmwareReleasesData.map((fw) => {
          if (fw.id === id) {
            Object.assign(fw, _set);
          }
          return fw;
        });
        return {
          id,
        };
      },
      createFirmwareRelease: (root: any, args: any) => {
        const {
          object: { comment, downloadLink, nodeSubType, versionName, versionNumber },
        } = args;
        const newFirmware = {
          id: firmwareReleasesData.length + 1,
          downloadLink,
          comment,
          isDeprecated: false,
          nodeSubType,
          uploadedDate: formatUTCTimestamp(moment()) as string,
          versionName,
          versionNumber,
        };
        firmwareReleasesData.push(newFirmware);
        return {
          id: newFirmware.id,
          versionName: newFirmware.versionName,
          versionNumber: newFirmware.versionNumber,
        };
      },
      createInfra: (root: any, args: any) => {
        if (args.type === InfrastructureDetailType.PIPE) {
          throw new GraphQLError("Create pipe failed.");
        }
      },
      insertKeyEntry: (root: any, args: any) => {
        const {
          object: { keyPositionId },
        } = args;

        if (keyPositionId === "333010e2-9fc9-4b15-9902-c6d937f61d03") {
          return keyEntries.filter((keyEntry) => !keyEntry.endedAt && keyEntry.keyEntryId === 175)[0];
        }
        if (keyPositionId === "333010e2-9fc9-4b15-9902-c6d937f61d04") {
          return keyEntries.filter((keyEntry) => keyEntry.keyEntryId === 176)[0];
        }
      },
      updateKeyEntries: (root: any, args: any) => {
        const {
          where: {
            keyEntryId: { _eq: keyEntryId },
          },
        } = args;

        if (keyEntryId === 175)
          return keyEntries.filter((keyEntry) => keyEntry.keyEntryId === keyEntryId && keyEntry.endedAt);
        return null;
      },
      unmapNode: (
        root: any,
        args: {
          comment?: string;
          nodeMacInput?: string;
          nodeType?: string;
          roomId?: string;
          rootCauseId?: number;
          scannedMacId?: string;
          slotName?: string;
        }
      ) => {
        const { roomId, nodeMacInput, scannedMacId } = args;
        const key = find(keysLabelDetails, (x) => {
          return x.rooms.find((o) => o.roomId === roomId);
        }) as (typeof keysLabelDetails)[number];

        key.rooms.forEach((x) => {
          if (x.roomId === roomId) {
            x.slots.forEach((o) => {
              const currentMappedNode = o.currentMappedNode.mappedNode.nodeMacId;
              if ((nodeMacInput || scannedMacId)?.includes(currentMappedNode)) {
                o.currentMappedNode.mappedNode.nodeMacId = "";
              }
            });
          }
        });
      },
      exitInstallationMode: (root: any, args: any) => {
        if (args.keyEntryId === 176) return null;
      },

      activateComfortPlus(_: any, args: any) {
        isActivateComfortPlus = true;
        comfortPlusData = {
          ...comfortPlusData,
          checkInAt: args?.checkInAt,
          checkOutAt: args?.checkOutAt,
          keyPositionId: args?.keyId,
          reservationGuests: args?.emails?.map((email: any) => ({
            user: {
              userId: "d7f18454-a0c6-4842-836d-362cbef07dcc",
              name: "email",
              email: email,
              __typename: "sensorflow_users",
            },
            comfortplusAccessToken: "mF3nYI1tycYwrl0JBHSEhJWst3i0z8DQqAQQUJBbAQa-6oW5zw",
            __typename: "sensorflow_reservation_guests",
          })),
        };
      },
    },
    query_root: {
      // simple for roles, just return the list
      roles: () => {
        return dummyRoles;
      },
      locationList: (root: any, args: any) => {
        const locationNameSrch: string = args.where?.locationName?._like?.replaceAll("%", "");
        return dummyLocations.filter((loc) => !locationNameSrch || loc.locationName.indexOf(locationNameSrch) >= 0);
      },
      locationsAssignedToUser: (root: any, args: any) => {
        const { filter, sort, pagination } = args;
        let locations = mockupLocations;

        locations = filterLocation(locations, filter);
        locations = sortLocation(locations, sort);

        return {
          locations,
          filterOptions: [
            {
              field: "mappingStatus",
              value: MappingStatus.Completed,
              label: "Completed",
              count: filterLocation(mockupLocations, { mappingStatus: [MappingStatus.Completed] }).length,
            },
            {
              field: "mappingStatus",
              value: MappingStatus.InProgress,
              label: "In Progress",
              count: filterLocation(mockupLocations, { mappingStatus: [MappingStatus.InProgress] }).length,
            },
            {
              field: "mappingStatus",
              value: MappingStatus.NotStarted,
              label: "Not Started",
              count: filterLocation(mockupLocations, { mappingStatus: [MappingStatus.NotStarted] }).length,
            },
          ],
          pagination: {
            count: locations.length,
            limit: pagination?.limit || 10,
            offset: pagination?.offset || 0,
          },
        };
      },
      keys: (root: any, args: any) => {
        const { filter, sort, pagination } = args;

        const location = get(
          mockupLocations.filter(({ id }) => id === filter.locationId),
          "0"
        );
        const allKeys = location.keysResponse.keys;
        const filteredByLocation = filterKey(allKeys, filter);
        const sortedKeys = sortKey(filteredByLocation, sort);
        const allCategories = sortBy(
          keyCategories.filter((category) => category.locationName === location.locationName),
          "category_name"
        );
        const categoryFilterOptions = allCategories.map((category) => ({
          field: "categoryName",
          value: category.categoryName,
          label: category.categoryName,
          count: filterKey(allKeys, { categoryName: [category.categoryName] }).length,
        }));

        // Check if has keyIds then return data with rooms
        // keyIds is used in energy consumption
        if (filter.keyIds) {
          return {
            keys: sortedKeys.map((k) => ({
              ...k,
              rooms: roomsWithEnergyConsumption,
            })),
          };
        }

        return {
          keys: sortedKeys,
          filterOptions: [
            {
              field: "mappingStatus",
              value: MappingStatus.Completed,
              label: "Completed",
              count: filterKey(allKeys, { mappingStatus: [MappingStatus.Completed] }).length,
            },
            {
              field: "mappingStatus",
              value: MappingStatus.InProgress,
              label: "In Progress",
              count: filterKey(allKeys, { mappingStatus: [MappingStatus.InProgress] }).length,
            },
            {
              field: "mappingStatus",
              value: MappingStatus.NotStarted,
              label: "Not Mapped",
              count: filterKey(allKeys, { mappingStatus: [MappingStatus.NotStarted] }).length,
            },
            ...categoryFilterOptions,
          ],
          pagination: {
            count: sortedKeys.length,
            limit: pagination?.limit || 10,
            offset: pagination?.offset || 0,
          },
        };
      },
      keysInLocation: (root: any, args: any) => {
        const { locationId } = args;
        return pick(mockupLocations.filter(({ id }) => id === locationId)[0], ["id", "locationName"]);
      },

      rapidMapping: (root: any, args: any) => {
        const { locationId } = args;
        return rapidMappings.filter(({ id }) => id === locationId)[0].rapidMapping;
      },

      room: (root: any, args: any) => {
        const { roomId: id } = args;
        return keysLabelDetails
          .find((key) => key.rooms.find(({ roomId }) => id === roomId))
          ?.rooms.find(({ roomId }) => id === roomId);
      },

      key: (root: any, args: any) => {
        const { keyId: id, filter } = args;

        const key = keysLabelDetails.find(({ keyId }) => id === keyId);

        if (filter?.mappingStatus === MappingStatus.Completed) {
          // labelPrintingKeyRooms query
          return {
            __typename: get(key, "__typename"),
            keyId: key?.keyId,
            keyName: key?.keyName,
            rooms: ((key?.rooms as any[]) ?? []).map((room: any) => pick(room, ["__typename", "roomId", "roomName"])),
          };
        }

        // labelDetailsForKey query
        return key;
      },

      userWithAuthToken: (root: any, args: any, context: any) => {
        const token = context.headers.authorization.replace("Bearer ", "");
        return userWithAuthTokens.find((user) => user.token === token)?.userWithAccessToken;
      },
      keyCategories: (root: any, args: any) => {
        const locationName = get(args.where, "locationName._eq");
        const locationId = get(args.where, "location.locationId._eq");
        const location = mockupLocations.find((location) => location.id === locationId);

        //response for key creation mode
        if (locationName) return keyCategoriesCreation;

        if (location?.locationName === "Chipmunk Hotel") {
          throw new GraphQLError("Error getting key categories");
        }

        return keyCategories.filter((category) => category.locationName === location?.locationName);
      },
      location: (root: any, args: any) => {
        const { locationId } = args;
        if (!locationId) return {};
        const location = mockupLocations.find((location) => location.id === locationId);

        location?.locationStats.nodeOverallStats.forEach((overallStat) => {
          overallStat.nodeType = overallStat.nodeType.toLowerCase();
        });

        return location;
      },
      locationMetadata: (root: any, args: any) => {
        const { locationId } = args;
        if (!locationId) return {};
        return dummyLocationMetadata.find((metadata) => metadata.locationId === locationId) || {};
      },
      positionConfigurations: (root: any, args: any) => {
        const filterBy4PFC = get(args, "where.actingMode._eq");
        const filterByoperationalMode = get(args, "where.operationalMode._eq");
        if (filterBy4PFC === "4pfc_as_2pfc" && filterByoperationalMode === "heating") return [];
        const filterByRoomIds = get(args, "where.positionId._in");
        if (filterByRoomIds.length > 0) {
          return mockPositionConfigurations;
        }
      },
      ACModels: (root: any, args: any) => {
        return ACModelsData;
      },
      gatewayImageDownloadUrl: (root: any, args: any) => {
        return "https://test-bucket-for-e2e-testing.s3.ap-southeast-1.amazonaws.com/deviceId.img";
      },
      commonRoomNames: (root: any, args: any) => {
        return ["Bedroom 1", "Bedroom 2"];
      },
      gateways: (root: any, args: any) => {
        if (args?.where?.position) {
          const {
            where: {
              position: { locationId },
            },
          } = args;
          return dummyGateways.filter((gateway) => gateway.locationId === locationId._eq);
        }
        return dummyGateways.filter((gateway) => !!!gateway?.locationId);
      },
      positions: (root: any, args: any) => {
        if (args.where?.parentPositionId?._eq === "333010e2-9fc9-4b15-9902-c6d937f61d03") {
          return roomsMetaData.filter((room) => room.positionId === "123410e2-9fc9-4b15-9902-c6d937f61d03");
        } else if (args.where?.parentPositionId?._eq === "333010e2-9fc9-4b15-9902-c6d937f61d02") {
          return roomsMetaData.filter((room) => room.positionId === "123410e2-9fc9-4b15-9902-c6d937f61d02");
        }
        return locationKeysAndRooms;
      },
      infrastructures: (root: any, args: any) => {
        let response: any[] = infrastructuresData;
        const {
          offset,
          limit,
          where: { hvacSystemType },
        } = args;
        const filterByName = get(args, "where._or[0].name._ilike");
        const filterByType = get(args, "where.type._in");
        const filterById = get(args, "where.id._eq");

        if (!isEmpty(hvacSystemType)) {
          return response.filter((infra: any) => infra.type === "CHILLED_WATER");
        }

        if (!isEmpty(filterById)) {
          if (filterById === "5ba7aa5f-20f5-4145-b03a-bd0aec900556") return [infrastructureData[0]];
          else if (filterById === "b47112d2-6df7-4f43-9bcd-49cfb7d2bb68") return [infrastructureData[1]];
          else if (filterById === "ba78e98f-ee47-4a68-bb68-4d3c781a2044") return [infrastructureData[2]];

          throw new GraphQLError("Infrastructure details is not found");
        }

        if (!isEmpty(filterByName)) {
          const search = filterByName.replaceAll("%", "");
          response = response.filter(
            (infra: any) =>
              infra.name.includes(search) ||
              infra.compressorPositionMappings.find((c: any) =>
                (c.position?.parentPosition?.positionName ?? "").includes(search)
              )
          );
        }
        if (!isEmpty(filterByType)) {
          response = response.filter((infra: any) => filterByType.includes(infra.type));
        }

        return response.slice(offset, offset + limit);
      },
      validateInfraNodeMapping: (root: any, args: any) => {
        const { nodeMacInput } = args;
        if (nodeMacInput === "04FFFF14B4570DF900" || nodeMacInput === "00FFFF000B575C368A") return;
        if (nodeMacInput === "00FFFF000B575C3661") throw new GraphQLError("mapped");
        if (nodeMacInput === "03FFFF000B575C35D7") throw new GraphQLError("Wrong Node type");
        if (nodeMacInput.length < 16) throw new GraphQLError("Node Mac Input is invalid");
        throw new GraphQLError("Cannot map with this node");
      },
      validateNodeBeforeMappingToSlot: (root: any, args: any) => {
        const { nodeMacInput } = args;
        if (
          nodeMacInput === "05FFFF14B4570DF900" ||
          nodeMacInput === "04FFFF14B4570DF90G" ||
          nodeMacInput === "02FFFF14B4570DF923" ||
          nodeMacInput === "0405FFFF14B4570DF9"
        ) {
          throw new GraphQLError("Wrong Node type", null, null, null, ["mapNode"], null, {
            mappedNodeInSlot: {
              locationName: "Pigeon Hotel",
              keyName: "Ascott 1104",
              roomName: "Bedroom",
            },
            code: NodeMappingError.ServerError,
          });
        }

        if (
          nodeMacInput === "03FFFF000B575C3661" ||
          nodeMacInput === "04FFFF14B4570DF911" ||
          nodeMacInput === "03FFFF000B575C3662" ||
          nodeMacInput === "04FFFF000B575C3662"
        ) {
          throw new GraphQLError("Node is already mapped", null, null, null, ["mapNode"], null, {
            mappedNodeInSlot: {
              locationName: "Pigeon Hotel",
              keyName: "Ascott 1104",
              roomName: "Bedroom",
            },
            code: NodeMappingError.NodeAlreadyMappedError,
          });
        }
        if (nodeMacInput === "03FFFF000B575C35D7") throw new GraphQLError("Wrong Node type");
        if (nodeMacInput.length < 16) throw new GraphQLError("Node Mac Input is invalid");
        if (nodeMacInput === "04FFFF000B575C3663")
          positionSubscriptionData[0].slotMappings[3].node.node_mac_id = "FFFF000B575C3663";
      },
      position: (root: any, args: any) => {
        return customerKeyDetailMockData;
      },
      emonMapInputInfo: (root: any, args: any) => {
        return {
          currentMapInput: 1,
          unavailableMapInputs: [2],
        };
      },
      locationOne: (root: any, args: any) => {
        // Empty location
        if (args.locationId === "27407651-106f-4bb3-98f6-efd87478b5d6") {
          return {
            keyCategories: [],
            positions_aggregate: {
              aggregate: {
                count: 0,
              },
            },
            locationKeys: [],
            locationId: args.locationId,
            positions: [],
            searchPositions: [],
            origin_latitude: 123.456,
            origin_longitude: 123.456,
          };
        }
        // Error location
        if (args.locationId === "cfbc10e2-9fc9-4b15-9902-c6d937f61d98") {
          throw new GraphQLError("Error getting keys");
        }
        return {
          keyCategories: keyCategories.filter((category) => category.locationName === "Pigeon Hotel"),
          locationId: args.locationId,
          positions: (posRoot: any, posArgs: any) => {
            const { args, limit, offset } = posArgs;
            let keys = keyListForCustomer(posArgs.where, args.searchbykeyname);
            return take(drop(keys, offset), limit)!;
          },
          locationKeys: (kRoot: any, args: any) => {
            return keyListForCustomer(args.where, args.searchbykeyname);
          },
          searchPositions: (posRoot: any, posArgs: any) => {
            const { args, limit, offset } = posArgs;
            let keys = keyListForCustomer(posArgs.where, args.searchbykeyname);
            keys = take(drop(keys, offset), limit)!;
            return keys.map((key) => ({
              ...key,
              searchRooms: key.rooms,
            }));
          },
          positions_aggregate: (paRoot: any, paArgs: any) => {
            return {
              aggregate: {
                count: keyListForCustomer(paArgs.where).length,
              },
            };
          },
          locationName: "Pigeon Hotel",
          origin_latitude: 123.84351,
          origin_longitude: 135.957364,
          organisation: {
            name: "SensorFlow Pte Ltd",
          },
          currency: "VND",
          timezone: "Etc/GMT+7",
          clickupListId: "123",
        };
      },
      nodeJoinStatuses: (root: any, args: any) => {
        return dummyNodeJoinStatuses;
      },
      nodeOnlineStatuses: (root: any, args: any) => {
        return dummyNodeOnlineStatuses;
      },
      sensorflow_f_get_event_stream: (root: any, args: any) => {
        const { positionid } = args.args;
        return eventStreamMockData.find((p) => p.positionId === positionid)?.eventsStream ?? [];
      },
      sensorflow_node_to_slot_mapping: (
        root: any,
        args: {
          where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
          limit: number;
          offset: number;
          order_by: Sensorflow_Node_To_Slot_Mapping_Order_By[];
        }
      ) => {
        const { where, limit, offset, order_by } = args;
        // filter
        let returnedNodes: any = filterNodes(nodes, where);

        // sort
        returnedNodes = sortNodes(returnedNodes, order_by?.[0])!;

        // paginate
        returnedNodes = take(drop(returnedNodes, offset), limit)!;
        return returnedNodes;
      },
      sensorflow_node_to_slot_mapping_aggregate: (root: any, args: any) => {
        return {
          aggregate: {
            count: nodes.length,
          },
        };
      },
      gatewayKittingProcessMetadata: (root: any, args: any) => {
        const {
          where: {
            locationId: { _eq: locationId },
          },
        } = args;

        return gateways
          .filter(({ locationId: _locationId }) => _locationId === locationId)
          .map((gateway) => ({
            balenaId: gateway.gatewayId,
            locationId: gateway.locationId,
            status: gateway.healthStatus,
            gateway: {
              gatewayMac: 0,
              gatewayName: gateway.gatewayName,
              wifiMacAddress: gateway.wifiMacAddress,
              gatewayHealthData: gateway.gatewayHealthData,
              hardware_type: GatewayHardwareType.MultiChannel,
            },
          }));
      },
      firmwareReleases: (root: any, args: any) => {
        if (args.limit) {
          const {
            offset,
            limit,
            where: {
              nodeSubType: { _eq: nodeSubType },
            },
            order_by,
          } = args;
          const filteredFirmwareReleases = firmwareReleasesData.filter((fw) => fw.nodeSubType === nodeSubType);
          const sortedFirmwareReleases = orderBy(
            filteredFirmwareReleases,
            Object.keys(order_by[0]),
            Object.values(order_by[0])
          );
          return take(drop(sortedFirmwareReleases, offset), limit);
        }
        return firmwareReleases;
      },
      sensorflow_firmware_releases_aggregate: (root: any, args: any) => {
        const {
          where: {
            nodeSubType: { _eq: nodeSubType },
          },
        } = args;
        const filteredFirmwareReleases = firmwareReleasesData.filter((fw) => fw.nodeSubType === nodeSubType);
        return {
          aggregate: {
            count: filteredFirmwareReleases.length,
          },
        };
      },
      firmwareUploadLink: (root: any, args: any) => {
        const { fileName } = args;
        return `https://firmware.sensorflow.org/${fileName}`;
      },
      sf_pms_smartalloc_report: (root: any, args: any) => {
        const {
          where: {
            locationId: { _eq: locationId },
            date: { _gte: startDate, _lte: endDate },
          },
        } = args;
        return smartAllocReport.filter((record) => {
          return (
            locationId === record.locationId &&
            moment(startDate) <= moment(record.date) &&
            moment(endDate) >= moment(record.date)
          );
        });
      },
      keyEntries: (root: any, args: any) => {
        return [];
      },
      getSmartRemValidationHistory: (root: any, args: any) => {
        const {
          where: {
            locationId: { _eq: locationId },
          },
        } = args;
        return smartRemValidationHistory.filter((data) => data.locationId === locationId) ?? [];
      },
      sensorflow_node_life_cycle_events: (root: any, args: any) => {
        return [];
      },
      sensorflow_node_to_position_mapping: (
        root: any,
        args: {
          limit: number;
          order_by: any[];
          where: {
            nodeMacId: {
              _eq: string;
            };
          };
        }
      ) => {
        const {
          nodeMacId: { _eq: nodeMacId },
        } = args.where;

        const key = find(keysLabelDetails, (key) => {
          return key.rooms.find((room) => {
            return room.slots.find((slot) => {
              return slot.currentMappedNode.mappedNode.nodeMacId === nodeMacId;
            });
          });
        }) as (typeof keysLabelDetails)[number];

        if (key)
          return [
            {
              decomissionedTime: null,
              installedTime: null,
              nodeMacId,
              positionId: key.keyId,
            },
          ];

        return [];
      },
      getNodeMappedPreviously: (root: any, args: any) => {
        const { infraId } = args;
        const nodePreviously = nodeMappedPreviously.find((node: any) => node.infraId === infraId);
        return nodePreviously ?? null;
      },

      sf_support_root_causes: (root: any, args: any) => {
        return rootCauses;
      },

      sensorflow_v_comfortplus_key_details: (root: any, args: any) => {
        return [
          {
            checkInDate: "2023-11-21T07:00:00+00:00",
            checkOutDate: "2023-11-23T05:00:00+00:00",
            positionName: "Man cave",
            onboarded: true,
            locationName: "Max Home",
            logoUrl: null,
            reservationId: 7,
            __typename: "sensorflow_v_comfortplus_key_details",
          },
        ];
      },

      sensorflow_precool_schedules: (root: any, args: any) => {
        return PrecoolSchedule;
      },

      sensorflow_timezones: () => {
        return moment.tz
          .names()
          .sort(sortByZone)
          .map((timezone, id) => ({ timezone, id }));
      },

      sensorflow_f_get_all_event_stream: (root: any, args: any) => {
        return activityLogs;
      },
    },
    subscription_root: {
      gatewayKittingProcessMetadata: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;
          const {
            where: {
              locationId: { _eq: locationId },
            },
          } = variables;

          return gateways
            .filter(({ locationId: _locationId }) => _locationId === locationId)
            .map((gateway) => ({
              balenaId: gateway.gatewayId,
              locationId: gateway.locationId,
              status: gateway.healthStatus,
              gateway: {
                gatewayMac: 0,
                gatewayName: gateway.gatewayName,
                wifiMacAddress: gateway.wifiMacAddress,
                gatewayHealthData: gateway.gatewayHealthData,
              },
            }));
        },
        subscribe: withFilter(
          () => pubsub.asyncIterator(["gatewayKittingProcessMetadata"]),
          (payload: Sensorflow_Warehouse_Kitting_Process_Metadata, variables: any) => {
            const {
              where: {
                locationId: { _eq: locationId },
              },
            } = variables;
            return payload.locationId === locationId;
          }
        ),
      },
      positions: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;

          if ((subscribePayloadCounts["positions"] || 0) > 2) return null;
          subscribePayloadCounts["positions"] = (subscribePayloadCounts["positions"] || 0) + 1;

          if (variables.where.positionId?._in) {
            return keyListForCustomer(variables.where);
          }

          if (variables.where.parentPositionId?._eq === "333010e2-9fc9-4b15-9902-c6d937f61d03") {
            return positionSubscriptionData;
          }

          if (isEqual(positionsSubscriptionConditions, variables.where)) {
            return null;
          }
          positionsSubscriptionConditions = variables.where;

          let locationId = variables.where.locationId?._eq;
          // To distinct with case positions loaded for key list screen
          if (locationId) {
            const { limit, offset } = variables;
            let keys = keyListForCustomer(variables.where);
            keys = take(drop(keys, offset), limit)!;
            return keys;
          }
          return positionSubscription;
        },
        subscribe: () => pubsub.asyncIterator("positions"),
      },
      locationOne: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;
          const { locationId } = variables;
          if (locationId === "27407651-106f-4bb3-98f6-efd87478b5d6") {
            const emptyLocationCategories = keyCategories.filter((c) => c.locationName === "Turkey Hotel");
            return {
              locationId,
              keyCategories: emptyLocationCategories,
              positions_aggregate: (root: any, args: any) => {
                return {
                  aggregate: {
                    count: 0,
                  },
                };
              },
              searchPositions: (root: any, args: any) => {
                return [];
              },
            };
          }
          const locationCategories = keyCategories.filter((c) => c.locationName === "Pigeon Hotel");
          return {
            keyCategories: locationCategories,
            searchPositions: (root: any, args: any) => {
              const { limit, offset } = args;
              let keys = keyListForCustomer(args.where);
              keys = take(drop(keys, offset), limit)!;
              return keys;
            },
            positions_aggregate: (root: any, args: any) => {
              return {
                aggregate: {
                  count: keyListForCustomer(args.where).length,
                },
              };
            },
          };
        },
      },
      nodeJoinStatuses: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;

          return dummyNodeJoinStatuses;
        },
      },
      sensorflow_node_to_slot_mapping: {
        resolve: (
          payload: any,
          variables: {
            where: Sensorflow_Node_To_Slot_Mapping_Bool_Exp;
            limit: number;
            offset: number;
            order_by: Sensorflow_Node_To_Slot_Mapping_Order_By[];
          }
        ) => {
          if (payload) return payload;

          const { where, limit, offset, order_by } = variables;
          // filter
          let returnedNodes: any = filterNodes(nodes, where);

          // sort
          returnedNodes = sortNodes(returnedNodes, order_by[0])!;

          // paginate
          returnedNodes = take(drop(returnedNodes, offset), limit)!;
          return returnedNodes;
        },
        subscribe: () => pubsub.asyncIterator(["sensorflow_node_to_slot_mapping"]),
      },
      sensorflow_node_to_slot_mapping_aggregate: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;

          if (subscribePayloadCounts["sensorflow_node_to_slot_mapping_aggregate"] > 0) return null;
          subscribePayloadCounts["sensorflow_node_to_slot_mapping_aggregate"] =
            (subscribePayloadCounts["sensorflow_node_to_slot_mapping"] || 0) + 1;

          return {
            aggregate: {
              count: nodes.length,
            },
          };
        },
        subscribe: () => pubsub.asyncIterator(["sensorflow_node_to_slot_mapping_aggregate"]),
      },
      positionsAggregate: {
        resolve: (payload: any, variables: any) => {
          if (payload) return payload;

          if (subscribePayloadCounts["positionsAggregate"] > 0) return null;
          subscribePayloadCounts["positionsAggregate"] = (subscribePayloadCounts["positionsAggregate"] || 0) + 1;

          return {
            aggregate: {
              count: keyListForCustomer(variables.where).length,
            },
          };
        },
        subscribe: () => pubsub.asyncIterator(["positionsAggregate"]),
      },
      position: {
        resolve: async (payload: any, variables: any) => {
          await new Promise((r) => setTimeout(r, 500));
          if (payload) return payload;
          if (subscribePayloadCounts["position"] > 0) return null;
          subscribePayloadCounts = {
            ...subscribePayloadCounts,
            position: (subscribePayloadCounts.position ?? 0) + 1,
          };
          if (variables.positionId === roomsMetaData[0].positionId) return roomsMetaData;

          const hasExpiredAt = customerKeyDetailMockData.rooms.find((r) => {
            const currentConfig = (r.positionConfiguration as any).find(
              (x: any) => x.recordType === "CURRENT"
            ) as (typeof r.positionConfiguration)[number];
            return !!currentConfig.expiredAt;
          });
          if (hasExpiredAt) return archiveCustomerKeyDetailMockData;
          const data = customerKeyDetailMockData;
          const rooms = data.rooms.map((room) => ({
            ...room,
            positionConfigurationsForActivityLog: room.positionConfiguration,
          }));
          return { ...data, rooms };
        },
      },
      sensorflow_f_get_event_stream: (payload: any, variables: any) => {
        if (payload) return payload;

        if (subscribePayloadCounts["sensorflow_f_get_event_stream"] > 0) return null;
        subscribePayloadCounts["sensorflow_f_get_event_stream"] =
          (subscribePayloadCounts["sensorflow_f_get_event_stream"] || 0) + 1;

        const { positionid } = variables.args;
        return eventStreamMockData.find((p) => p.positionId === positionid)?.eventsStream ?? [];
      },
      keyEntries: {
        resolve: async (payload: any, variables: any) => {
          if (payload) return payload;
          if (subscribePayloadCounts["keyEntries"] > 0) return null;
          subscribePayloadCounts["keyEntries"] = (subscribePayloadCounts["keyEntries"] || 0) + 1;
          return keyEntries.filter((keyEntry) => keyEntry.keyEntryId === 177);
        },
      },
      infrastructures: {
        resolve: async (payload: any, variables: any) => {
          if (payload) return payload;
          if (subscribePayloadCounts["infrastructures"] > 5) return null;
          subscribePayloadCounts["infrastructures"] = (subscribePayloadCounts["infrastructures"] || 0) + 1;

          let response: any[] = infrastructuresData;
          const {
            offset,
            limit,
            where: { hvacSystemType },
          } = variables;
          const filterByName = get(variables, "where._or[0].name._ilike");
          const filterByType = get(variables, "where.type._in");
          const filterById = get(variables, "where.id._eq");
          const filterByIdIn = get(variables, "where.id._in");

          if (!isEmpty(hvacSystemType)) {
            return response.filter((infra: any) => infra.type === "CHILLED_WATER");
          }

          if (!isEmpty(filterById)) {
            if (filterById === "5ba7aa5f-20f5-4145-b03a-bd0aec900556") return [infrastructureData[0]];
            else if (filterById === "b47112d2-6df7-4f43-9bcd-49cfb7d2bb68") return [infrastructureData[1]];
            else if (filterById === "ba78e98f-ee47-4a68-bb68-4d3c781a2044") return [infrastructureData[2]];

            throw new GraphQLError("Infrastructure details is not found");
          }

          if (!isEmpty(filterByName)) {
            const search = filterByName.replaceAll("%", "");
            response = response.filter(
              (infra: any) =>
                infra.name.includes(search) ||
                infra.compressorPositionMappings.find((c: any) =>
                  (c.position?.parentPosition?.positionName ?? "").includes(search)
                )
            );
          }

          if (!isEmpty(filterByIdIn)) {
            response = response.filter((infra: any) => filterByIdIn.includes(infra.id));
          }

          if (!isEmpty(filterByType)) {
            response = response.filter((infra: any) => filterByType.includes(infra.type));
          }

          return response.slice(offset, offset + limit);
        },
      },
      locationMetadata: {
        resolve: async (payload: any, variables: any) => {
          await new Promise((r) => setTimeout(r, 500));
          if (payload) return payload;
          if (subscribePayloadCounts["locationMetadata"] > 0) {
            return null;
          }
          subscribePayloadCounts = {
            ...subscribePayloadCounts,
            locationMetadata: (subscribePayloadCounts["locationMetadata"] ?? 0) + 1,
          };
          return dummyLocationMetadata.filter(
            (locationMetadata) => locationMetadata.locationId === variables.locationId
          )[0];
        },
      },
      sensorflow_v_comfortplus_key_measurements: {
        resolve: () => {
          return [];
        },
      },
      sensorflow_v_comfortplus_precool_status: {
        resolve: () => {
          return false;
        },
      },
    },
  };
};

// Add mocks, modifies schema in place
const mocks = {
  User: (root: any, args: any) => {
    // if we have userId and it exists in the dummyusers, return the exact user
    if (args.userId && dummyUsers.some((user) => user.userId === args.userId)) {
      return dummyUsers.find((user) => user.userId === args.userId);
    }
    return {
      userId: () => args.userId || faker.random.uuid(),
      email: () => faker.internet.email(),
      name: () => faker.name.findName(),
      roles: (_root: any, _args: any, context: any, info: any) => {
        // ninja returning the internals, there must be a better way of doing this
        const possibleValuesInEnum =
          // eslint-disable-next-line no-underscore-dangle
          info.returnType.ofType.ofType.ofType._values;
        // console.log(info.returnType.ofType.ofType.ofType._values);

        // make an length of random length from 1 to enum length
        const randomArrayLength = random(1, possibleValuesInEnum.length - 1);
        // then have array members also of random index
        const returnArray: string[] = [];
        for (let i = 0; i < randomArrayLength; i++) {
          const randomItem = possibleValuesInEnum[random(0, possibleValuesInEnum.length - 2)].value;
          // don't push duplicates
          if (!returnArray.includes(randomItem)) {
            returnArray.push(randomItem);
          }
        }

        return returnArray;
      },
    };
  },
  UsersResponse: (root: any, args: any) => {
    // first filter the dummyUsers to any search string
    const processedUsers = filterUsers(args.filter) || [];

    return {
      users: () => {
        const { offset, limit } = args.pagination;
        return processedUsers.slice(offset, offset === 0 ? limit : offset + limit);
        // return new MockList(80);
      },
      pagination: () => {
        return {
          count: processedUsers.length,
          limit: args.pagination?.limit || 10,
          offset: args.pagination?.offset || 0,
        };
      },
    };
  },
  // Role: () => {
  //   return dummyRoles[getRandomInt(0, dummyRoles.length)];
  // },
  OrganisationsResponse: (root: any, args: any) => {
    return {
      organisations: () => {
        // search
        return dummyOrganisations.filter((organisation) => {
          const organisationName = organisation.name.toUpperCase();
          return organisationName.includes(args.filter.name.toUpperCase());
        });
      },
    };
  },
  LocationsResponse: (root: any, args: any) => {
    let locations = dummyLocations.map((location, index) => {
      const totalCount = random(10, 100);
      let mappedCount = index % 3 === 0 ? 0 : totalCount;
      mappedCount = index % 3 === 2 ? mappedCount : random(Math.floor(mappedCount / 2), mappedCount);

      return {
        ...location,
        locationStats: {
          mappingStatus: ["NOT_STARTED", "IN_PROGRESS", "COMPLETED"][index % 3],
          keysStats: {
            totalCount,
            mappedCount,
          },
        },
      };
    });
    locations = filterLocation(locations, args.filter);
    locations = sortLocation(locations, args.sort);

    return {
      locations: () => locations,
      pagination: {
        count: locations.length,
        limit: args.pagination?.limit || 10,
        offset: args.pagination?.offset || 0,
      },
    };
  },
  uuid: () => faker.random.uuid(),
  timestamptz: () => "2021-04-13T10:14:17.941968+00:00",
  timestamp: () => "2021-04-13T10:14:17.941968",
  numeric: () => faker.random.number(-1),
  smallint: () => faker.random.number({ min: -32768, max: 32767 }),
};

export const initmockGraphQLClientFn = () => {
  // all the graphql-tools magic below
  // Make a GraphQL schema with no resolvers
  const schema = makeExecutableSchema({
    typeDefs: pactSchema,
    resolvers: reinitMockResolvers(),
  });

  const schemaLink = new SchemaLink({
    schema,
    context: (req: any) => {
      const token = localStorage.getItem("access_token") || "warehouse-user-access-token";

      return {
        headers: {
          ...req.headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    },
  });

  const preserveResolvers = true;
  addMocksToSchema({ schema, mocks, preserveResolvers });

  const mockClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: schemaLink,
  });
  return mockClient;
};

const mockGraphQLClient = initmockGraphQLClientFn();

export default mockGraphQLClient;
